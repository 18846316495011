import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Themes from '../themes/Themes';
import './Sidebar.scss';

export default function Sidebar() {
  const sidebarRef = React.useRef<HTMLDivElement>(null);

  const openSidebar = () => {
    if (sidebarRef.current) {
      if (sidebarRef.current.classList.contains('open')) {
        sidebarRef.current.classList.add('close');

        setTimeout(() => {
          sidebarRef.current?.classList.remove('close');
        }, 1000);
      }

      sidebarRef.current.classList.toggle('open');
    }
  };

  return (
    <>
      <svg
        onClick={openSidebar}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 14.1788 16.20724"
        className="menu-icon"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              className="cls-el cls-el-1"
              d="M0,0H5.74049a0,0,0,0,1,0,0V6.31628a.29644.29644,0,0,1-.29644.29644H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
            />
            <path
              className="cls-el cls-el-2"
              d="M8.43831,0H14.1788a0,0,0,0,1,0,0V6.61272a0,0,0,0,1,0,0H8.74134a.303.303,0,0,1-.303-.303V0A0,0,0,0,1,8.43831,0Z"
            />
            <path
              className="cls-el cls-el-3"
              d="M0,9.59452H5.43617a.30432.30432,0,0,1,.30432.30432v6.3084a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V9.59452A0,0,0,0,1,0,9.59452Z"
            />
            <path
              className="cls-el cls-el-4"
              d="M8.742,9.59452H14.1788a0,0,0,0,1,0,0v6.61272a0,0,0,0,1,0,0H8.43831a0,0,0,0,1,0,0V9.8982A.30367.30367,0,0,1,8.742,9.59452Z"
            />
          </g>
        </g>
      </svg>

      <div className="sidebar" ref={sidebarRef}>
        <Themes />
        <div className="sidebar__item">
          <span className="sidebar__text">
            <a
              className={'top-menu-icon'}
              target="_blank"
              href="https://vegax.gg/"
            >
              <div className="discord"></div>
            </a>
          </span>
        </div>
        <div className="sidebar__item">
          <span className="sidebar__text">
            <a target="_blank" href="https://vegax.gg/">
              Download Vega-X
            </a>
          </span>
        </div>
        <div className="sidebar__item">
          <span className="sidebar__text">
            <Link
              to={{
                pathname: `/`,
              }}
            >
              Scripts
            </Link>
          </span>
        </div>
        <div className="sidebar__item">
          <span className="sidebar__text">
            <a href="https://www.youtube.com/channel/UCPnCsR8_hY_z7tceY5-0KSA?sub_confirmation=1">
              Subscribe
            </a>
          </span>
        </div>
        <div className="sidebar__item">
          <span className="sidebar__text">
            <Link
              to={{
                pathname: `/info`,
              }}
            >
              Info
            </Link>
          </span>
        </div>
      </div>
    </>
  );
}
