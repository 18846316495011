import React from 'react';
import {Link} from "react-router-dom";
import Themes from '../themes/Themes';
import "./TopMenu.scss";

function TopMenu() {
    const contactForm = false;

    return (
        <div className="top-menu">
            <Themes/>
            <a className={'top-menu-icon'} target="_blank" href="https://vegax.gg"><div className="discord"></div></a>
            <a className={'download-link'} href="https://vegax.gg">Download Vega-X</a>
            <Link to={{
                pathname: `/`
            }}>
                Scripts
            </Link>
            <a href="https://www.youtube.com/channel/UCPnCsR8_hY_z7tceY5-0KSA?sub_confirmation=1">Subscribe</a>
            <Link to={{
                pathname: `/info`
            }}>
                Info
            </Link>
            {contactForm ? <Link to={{pathname: `/contact`}}>CONTACT</Link> : null}
        </div>
    );
}

export default TopMenu;
